::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    $global-black-grey;
    font-family:$mirr-sans-font;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:    $global-black-grey;
   opacity:  1;
       font-family:$mirr-sans-font;

}
::-moz-placeholder { /* Mozilla Firefox 19+ */
   color:    $global-black-grey;
       font-family:$mirr-sans-font;

   opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:    $global-black-grey;
       font-family:$mirr-sans-font;

}
:placeholder-shown { /* Standard (https://drafts.csswg.org/selectors-4/#placeholder) */
  color:    $global-black-grey;
      font-family:$mirr-sans-font;

}