// 7 - Footer Nav
// --------------------------------
#footer-menu {
    color: $global-text-on-black;
    font-size:$font-size-default;
    a {
        color: $global-text-on-black;
    }
    #footersmenu {
        li {
            display: block;
            float: left;
        }

        a {
            padding: 0.4rem 1rem;
            line-height:$leading-19pt;
        }
    }
}


@media #{$small-only} {

    #footer-menu{
        #footersmenu{
            padding-right:0;
            li{
                width:100%;
            }
            a{
                text-align:center;
                padding-right:0;
            }
        }
    }
    .logocol{
        margin-right:0;
        padding-right:0;
    }

}
