// 1 - menu
// --------------------------------
#root {
    margin-top: 0;
    height: 100%;
}

div#mainNav {
    .sticky.is-stuck {
        background: $global-black-grey;
    }
}

.top-bar-container {
    height: 6rem;
}

div#main-menu {
    font-family: $mirr-font;
    font-size: $font-size-sm;

    ul.menu {
        li {

            a {
                color: $global-text-on-black;
                text-transform: $menu-text-transform;
                text-shadow: $menu-text-shadow;
                text-align: center;
                padding: 0.7rem 1.9rem;
                position: relative;
                text-decoration: none;
                position: relative;
                display: block;

                span {
                    width: 100%;
                    /*position: absolute;*/
                    /*left: 0;*/
                }

                &:hover {
                    span {
                        padding-bottom: 0.4rem;
                        bottom: 1px;
                        border-width: 0 0 1px;
                        border-style: solid;
                    }
                }
            }

            &:first-child {
                a {
                    padding-left: 0;
                }
            }

            &:last-child {
                a {
                    padding-right: 0;
                }
            }
        }

        li.active {
            span {
                padding-bottom: 0.4rem;
                bottom: 1px;
                border-width: 0 0 1px;
                border-style: solid;
            }
        }
    }

    nav.top-bar {
        text-align: center;
        /*background:$global-header-footer-black;*/
    }
}

.title-bar {
    background: none;
    width: 100%;
    text-align: center;

    a {
        color: $global-text-on-black;
        text-transform: $menu-text-transform;
        text-shadow: $menu-text-shadow;
        text-align: center;
    }

    .row {
        background: $global-black-grey;
    }
}

.top-bar, .top-bar ul {
    background: none;
}

.top-bar {
    height: 0;
    padding-top: .5rem;
    
}
.title-bar-title{
    font-weight:normal;
}
.is-stuck {
    .top-bar {
        padding-top: 0.5rem;
    }
}

.sticky-navigation {
    z-index: 35;
}

.sticky-navigation.is-stuck {
    z-index: 36;
}

@media #{$small-only} {
    .top-bar {
        padding: 0;
        .top-bar-left{
            background:$global-black-grey;
        }
        .row {
            background-color: $global-black-grey;
        }
    }

    div#main-menu {
        ul.menu {
            li {

                &:first-child {
                    a {
                        padding-left: 0.5rem;
                    }
                }

                &:last-child {
                    a {
                        padding-right: 0.5rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:1250px ) {
    div#main-menu {
        ul.menu {
            li {
                a{
                    padding: 0.7rem 1.5rem;
                }
            }
        }
    }
}

@media screen and (max-width:1150px ) {
    div#main-menu {
        ul.menu {
            li {
                a{
                    padding: 0.7rem 1rem;
                }
            }
        }
    }
}

@media screen and (max-width:1050px ) {
    div#main-menu {
        ul.menu {
            li {
                a{
                    padding: 0.7rem 0.5rem;
                }
            }
        }
    }
}

@media screen and (max-width:950px ) {
    div#main-menu {
        ul.menu {
            li {
                a{
                    font-size:0.7rem;
                }
            }
        }
    }
}
@media screen and (max-width:750px ) {
    div#main-menu {
        ul.menu {
            li {
                a{
                    font-size:0.6rem;
                }
            }
        }
    }
}
