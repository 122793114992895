// 4 - Booking Bar
// --------------------------------
.book-bar-contain {
    position: relative;
    min-width: 100%;
    margin-top: -7rem;
}

.booking-bar {
    .outerWrapper {
        background: $global-black-grey-opaque;
        /*padding:1rem 0;*/
        .contain {
            height: 3rem;
            padding-top:0.6rem;
        }

        
    }
}
.is-stuck .outerWrapper {
    background: $global-black-grey;
}
.booking-bar {
    .logo-holder {
        display: block;
        width: 100%;
    }

    .logo-area {
        img {
            /*padding: 1.1rem 0;*/
            margin: 0 auto;
            width: auto;
            display: block;
            /*padding: 0.9rem 0 0;*/
            margin-top:5px;
            max-height:35px;
            height:35px;
        }
    }

    .iconwrap {
        font-size: 80%;
        padding: 0 5px;
    }
}

#booking-form {
    label, p {
        color: $booking-menu-text-color;
        font-family: $mirr-font;
    }

    p.tinypad {
        text-align: right;
        padding-top: 1rem;
        font-size: $font-size-default;
        line-height: 1;
        margin-top:-1px;
        text-transform:uppercase;
        /*border: 1px solid transparent;*/
    }

    .bigbutton {
        width: 100%;
        display: block;
        float: right;
        font-family: $mirr-font;
        text-transform: uppercase;
        font-size: $font-size-default;
        margin-bottom: 0;
    }
}


@media #{$small-only} {
    .top-bar {
        padding: 0;

        .row {
            background-color: $global-black-grey;
        }
    }
    #booking-form{
        padding-left:0;
        .bookingbutton{
            padding-left:0;
        }
    }
}
