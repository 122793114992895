
// 5 - Footer General
// --------------------------------
.site-footer{
    font-family:$mirr-sans-font;
    width: 100%;
    display: inline-block;
    background-size: cover;
    padding:4rem 1rem 2rem;
    p, ul{
    font-size:$font-size-14;
    line-height:$leading-19pt;
    }
    .logo-holder{
        line-height:5rem;
        img{
          max-height:2rem;
        }
    }
}
#destroy{
    position:fixed;
    bottom:0;
    display: block;
    height: 20px;
    background: #F00;
    width:100%;
}
.footer-freetext{
    
}