.widgetTextOnly{
    /*padding:1rem;*/
    text-align:center;
    h3, a{
        font-family:$mirr-font;
        text-align:center;
    }
    h3{
        font-style:italic;
        font-size:$font-size-30;
        line-height:$leading-37pt;
        font-weight:$font-lighter;
        padding-top:0.9rem;
    }
    a{
        text-transform:uppercase;
        color:black;
        font-size:$font-size-sm;
    }
}