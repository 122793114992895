.slideshow {
    margin: auto;
    /*max-width: 2000px;*/
    width: 100%;  
    height:680px; 
    overflow:hidden;
    /*background:$global-black-grey;*/
     .slick-slide{
       min-height:680px;
       background-size:cover !important;
       background-repeat:no-repeat !important;
       background-position:50% 50% !important;
    }
    .slide-image{
        min-height:680px;
        display:block;
        /*display:table-cell;*/
        vertical-align:middle;
        margin:0 auto;
        display:none;
    }
    
}
.slick-text{
    position:absolute;
    height:auto;
    top:13rem;
    text-align:center;
    width:100%;
    h2{
        font-family:$mirr-font;
        font-weight:lighter;
        color:$global-text-on-black;
        text-shadow:$global-text-shadow;
        /*padding: 0 28rem 2rem;*/
        max-width:800px;
        width:90%;
        margin:0 auto;
        text-align:center;
        font-size:3.6rem;
        line-height:1.46;
        font-style:normal;
    }
    img.trip-adv-logo{
        margin:1.5rem auto 0 auto;
        /*width:125px;*/
        
    }
}
#triplogo{
    top:14rem;
    margin-top:4rem;
}


@media #{$small-only} {
    .slick-text{
        top:10rem;
        h2{
            font-size:1.6rem;
        }
    }
    .slideshow{
        height:450px;
        .slick-slide{
         min-height:450px;
        }
    }
}