.text{
	position:absolute;
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);
	text-align:center;
	width:90%;
	h2,h3,h4{
		font-weight: lighter;
		line-height: 1.25em;
	}	
	a{
		cursor:pointer;
		&:hover{
			text-decoration:underline;
		}
	}
	.link{
		display:inline-block;
		font-weight: normal;
		padding-top:0.75rem;
		text-transform: uppercase;
  	font-size: 0.8rem;
	}
	.large-text{
		font-size:5vw;
		@media screen and (min-width:$tablet){
			font-size:2vw;
		}
		@media screen and (min-width:$desktop_large){
			font-size:1.8rem;
		}
		font-style:italic;
		font-weight: lighter;
		line-height: 1.25em;
		text-decoration:none;
		color:inherit;
		&.quote{
			font-size:4vw;
            border:0;
            padding:0;
            margin:0;
			@media screen and (min-width:$tablet){
				font-size:2vw;
			}
			@media screen and (min-width:$desktop_large){
				font-size:1.4rem;
			}
			&:before{
				content:"\201c";
			}
			&:after{
				content:"\201d";
			}
		}
	}
	.title {
		font-size: 5vw;
		margin-bottom:0.25em;
		
		@media screen and (min-width:$tablet){
			text-align: right;
			font-size: 3vw;
		}
		@media screen and (min-width:$tablet){
			font-size:3.6vw;
		}
		@media screen and (min-width:$tablet_large){
			font-size:3vw;
		}
		@media screen and (min-width:$desktop_large){
			font-size:2.4rem;
		}
  	
	}
	.description {
		font-weight: 100;
		line-height: 1.5em;
		font-size:3vw;
		margin-bottom:1rem;
		@media screen and (min-width:$tablet){
			font-size:1.8vw;
			text-align:right;
			margin-bottom: 2rem;
		}
		@media screen and (min-width:$tablet_large){
			font-size: 1.4vw;
		}
		@media screen and (min-width:$desktop_large){
			font-size:1rem;
		}
	}
	.button{
		padding:0.8em 1em;
		background:$brown;
		cursor:pointer;
		font-weight:lighter;
		font-size: 3vw;
		color:$white;
		@media screen and (min-width:$tablet){
			font-size:1rem;
			float:right;
		}
		text-transform:uppercase;
		text-decoration:none;
		transition: background 0.5s ease-in-out;
		&:hover,
		&:focus{	
			text-decoration:none;
			background:$brown_dark;
		}
	}
}