.WidgetGoogleMapsWidget{
    
    color:$global-text-on-black;
    a{
        font-size:$font-size-sm;
    }
    img{
        /*padding-top:3rem;*/
    }
    a.button{
        color:$global-text-on-black;
        float:right;
        position:absolute;
        margin-right:2em;
        margin-bottom:2rem;
        bottom:0;
        right:0;
    }
    h3{
        font-size:$font-size-50;
        line-height:$leading-48pt;
        text-align:right;
        font-weight: 100;
        font-style: normal;
    }
    p{
        font-size:$font-size-17;
        line-height:$leading-27pt;
        text-align:right;
        font-weight:$font-lighter;
    }
    .posRel{
        position:relative;
        min-height:356px;
    }
}

@media #{$small-only} {
    .WidgetGoogleMapsWidget{
        a.button{
            position:relative;
            margin-right:0;
            margin-bottom:0;
        }
    }
}
