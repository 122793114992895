// 2 - social menu
// --------------------------------
.social-bar{
    background: $global-black-grey;
    float:right;
    position:fixed;
    right:0;
    top:10rem;
    z-index:100;


    ul.menu li{
        clear:both;
        float:left;
        a{
            color:$global-white;
            i{
                font-size:150%;
            }
        }
        a:hover{
            color:$global-white-hovered;
        }
    }
}