.WidgetTopbottomImagecontent{
    background:$global-black-grey;
    a, h1, h2, h3, h4, p{
        color:$global-text-on-black;
    }
    h3{
        font-size:$font-size-42;
        line-height:$leading-48pt;
        text-align:right;
    }
    p{
        font-size:$font-size-17;
        line-height:$leading-27pt;
        text-align:right;
    }
}