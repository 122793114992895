.widget-area-2
{
    height:$widgetHeight;
    min-height:$widgetHeight;
    max-height:$widgetHeight;

    img{
        height:$widgetHeight;
        min-height:$widgetHeight;
        max-height:$widgetHeight;
    }

    /*background:$global-header-footer-black;*/
    text-align:center;
    h3, p{
        font-family:$mirr-font;
        text-transform:initial;
        line-height:$leading-37pt;
    }
    h3{
        font-weight:$font-lighter;
        font-style:italic;
        /*padding-top:0.6rem;*/
    }
    p{
        /*text-transform:uppercase;*/
    }
    a{
        color:white;

    }
    a:hover{
        color:white!important;
    }
    .widget-area-2-with-text{
        padding:3.6rem 0;
    }
}
