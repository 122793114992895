&.image{
	background-size:cover;
	background-position: center center;
	
	&.top-left-interest{
		background-position: left top;
	}
	&.top-center-interest{
		background-position: center top;
	}
	&.top-right-interest{
		background-position: right top;
	}
	&.center-left-interest{
		background-position: left center;
	}
	&.center-center-interest{
		background-position: center center;
	}
	&.center-right-interest{
		background-position: right center;
	}
	&.bottom-left-interest{
		background-position: left bottom;
	}
	&.bottom-center-interest{
		background-position: center bottom;
	}
	&.bottom-right-interest{
		background-position: right bottom;
	}
	
	/*Hide Image but Retain advantages of text and SEO*/
	img{
		display:none;
	}
}