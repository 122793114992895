$widgetHeight:240px;

.widgetRow{
    position:relative;
}

.WidgetGlobal{
    h1, h2, h3, h4, a{
        font-family:$mirr-font;
        text-align:center;
    }
    h1, h2, h3, h4{
        font-style:italic;
    }
    h3{
        line-height:$leading-37pt;
        font-weight:$font-lighter;
    }
    a{
        text-transform:uppercase;
        color:black;
        &:hover{
            /*font-style:italic;*/
            text-decoration:underline;
        }
    }
    p{
        text-align:center;
    }
}

.widgetImage{
    img{
        height:$widgetHeight;
        min-height:$widgetHeight;
        max-height:$widgetHeight;
    }
}

.valign-holder{
    dispaly:table;
    position:relative;
}
.valign-container{
    display:table-cell;
    vertical-align:middle;
}

@media screen and (min-width: 40em){
    .widgetRow, .row .row{
        margin-left:auto;
        margin-right:auto;
    }
}