$fontFileName: "../fonts/general_foundicons";
$fontName: "GeneralFoundicons";
$classPrefix: "foundicongeneral-";

@mixin i-class($name,$pua) {
  .#{$classPrefix}#{$name}:before { 
    content: "\f#{$pua}";
  }
}

@mixin ie-class($name,$pua) {
  .#{$classPrefix}#{$name} { 
    *zoom: expression( this.runtimeStyle['zoom'] = "1", this.innerHTML = "&#xf#{$pua};"); 
  }
}

@mixin face {
  @font-face { 
    font-family: $fontName;
    src: url('#{$fontFileName}.eot');
    src: url('#{$fontFileName}.eot?#iefix') format('embedded-opentype'),
         url('#{$fontFileName}.woff') format('woff'),
         url('#{$fontFileName}.ttf') format('truetype'),
         url('#{$fontFileName}.svg##{$fontName}') format('svg');
    font-weight: normal;
    font-style: normal;
  }
}