.ContentPages{
    #lefthandcolumn{
        color:$global-text-on-black;
        /*background: $global-black-grey;*/
    }
    #righthandcolumn{

    }
    .contentHeaderArea{
        background: $global-black-grey;
    }
}

.dont-show{
    display:none;
}


.subContentPageQuote{
    background:white;
    color:black;
    padding:1rem;
}
