body, .mirrFontForce, .pure-g, .pure-g [class *="pure-u"] {
    /* sorry */
    font-family: $mirr-font !important;
}
.forceTimes{
    font-family:'Times New Roman';
}

h1, h2, h3, h4 {
    font-family: $mirr-font;
    font-style: italic;
    text-align: center;
}

a {
    color: black;

    .center {
        text-align: center;
        display: block;
    }
    /*:hover{
        color:black;
    }*/
}

p {
    .center, .center a {
        text-align: center;
    }
}

.setBlack {
    background-color: $global-black-grey;

    .widgetTextOnly h3 {
        color: #fff;
        font-size: .9em;
        font-style: normal;
        line-height: 1.5em;
        margin-bottom: 0;
        padding-top: 0;
    }
}

.no-margin {
    margin: 0;
}

.padded {
    padding: 1rem;
}

.double-padded {
    padding: 2rem;
}


br.clearance {
    width: 100%;
    display: block;
    clear: both;
}

.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

* html .clearfix {
    zoom: 1;
}
/* IE6 */
*:first-child + html .clearfix {
    zoom: 1;
}
/* IE7 */


@media #{$small-only} {
    .row {
        .row {
            margin-left: 0;
            margin-right: 0;
        }
    }
}
