.customContentPages {
    ul.customContentPagesMenu, div.customContentPagesMenu {
        padding: 1rem 0;
        background: $global-black-grey;
        /*width:100%;*/
        li {
            padding: 0rem;
            float: left;

            span {
            }

            &:hover {
                span {
                    padding-bottom: 0.7rem;
                    bottom: 1px;
                    border-width: 0 0 1px;
                    border-style: solid;
                    border-color: white;
                }
            }
        }
        /*li:hover, li.active{
            border-bottom:1px solid white;
        }*/
        li, li a {
            text-align: center;
            font-family: $mirr-font;
            color: $global-text-on-black;
            text-transform: uppercase;
            font-size: 96%;
        }
    }

    .pageTextArea {
        font-size: $font-size-17;
        line-height: $leading-27pt;
    }

    .contentHeader {
        font-size: $font-size-42;
        line-height: $leading-48pt;
        text-align: right;
				@media #{$xlarge-up} {
					width: 49%;
				}
        float: right;
        font-style: normal;
        font-weight: 300;
        height: 13rem;
        font-size: 2.7rem;
        line-height: 3rem;
        text-align: right;
        font-weight: 100;
        font-style: normal;
    }

    .contentHeaderArea {
        min-height: 25rem;
    }

    .contentsubtext {
        clear: both;
        width: 100%;
        font-size: $font-size-17;
        line-height: $leading-27pt;
    }

    div.coverblack {
        background: $global-black-grey;
    }
}

.ContentPagesMaster {
    background: $global-black-grey;
}


.subContentPageQuote {
    padding: 2rem;
}

@media #{$small-only} {
    .customContentPages {
        .pageTextArea {
            padding: 0 2rem;
        }
        .contentHeader{
            height:auto;
            padding-bottom:2rem;
        }
        .contentHeaderArea {
            min-height: 10rem;
            height:auto;
            padding:0 2rem;
            h2{
                width:100%;
                text-align:center;
            }
        }

        ul.customContentPagesMenu, div.customContentPagesMenu {
            /*width:100%;*/
            li 
            {
                width:100%;
                font-weight:lighter;
                margin-bottom:1rem;
                &:hover{
                    span{
                        padding-bottom:0.2rem;
                    }
                }
            }
        }
    }
}



/* MAP */

.gmapscontainer{
    position:relative;
    padding-bottom:75%;
    height:0;
    overflow:hidden;
    .gmapsiframe{
        position:absolute;
        top:0;
        left:0;
        width:100% !important;
        height:100% !important;
    }
}