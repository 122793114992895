
.content{
    position: relative;
    display: block;
    margin: 0 auto;
}
.no-padding{
    padding:0;
}
.image{
    display:inline-block;
   background-size:cover;
   background-position:50% 50%;
   background-repeat:no-repeat;
   img{
       opacity:0;
    }
}