.footernewsletter {
    &.headingnewsletter {
        padding-top: 1rem;
        color: white;
        background: $black_bg;

        .content {
            padding-right: 0;
            padding-left: 0;
            margin-right: 0;
            margin-left: 0;
            background: $black_bg;
            display: inline-block;
        }

        p {
            margin-bottom: 0;
            line-height: 2.5;
            text-align: center;
        }

        .callout {
            margin-bottom: 0;
            text-align: center;
            color: black;
        }

        .callout-content {
            width: 100%;
            .callout {
                width: 75%;
                margin: 0 auto;
            }
        }

        .home-grid-wrap {
            background: transparent;
        }

        label {
            color: white;

            a {
                color: white;
                text-decoration: underline;
            }
        }
    }
}
@media #{$small-only} {
    .footernewsletter{
        float:none !important;
        /*max-width:90%;*/
        margin:0 auto;
        padding-left:0;
        background:pink;
        .no-right-padding.columns{
                padding:0;
            }
        section.footernewsletter{
            padding: 0;
            width: 90%;
            float: none;
            margin: 0 auto;
            
        }
        
    }
}

