/* -----------------------   Breakpoints --------------------------- */ 

$low_vertical:30em;

$phablet: 30.0625em;
$phablet_max: 30em;

$tablet: 32.5625em;
$tablet_max: 32.5em;

$tablet_large: 47.4375em;
$tablet_large_max: 47.375em;

$desktop: 64.0625em;
$desktop_max: 64em;

$desktop_large: 75.0625em;
$desktop__large_max: 75em;

/* screen types */
$half_retina: "(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)";
$retina: "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)";



$white: #fff;
$brown:#9A7C61;
$brown_dark:#725C4A;



.grid-row{
	
	text-rendering: optimizeLegibility;
	font-smooth: always;
	font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-webkit-font-smoothing: antialiased;
	
	
	
	width:100%;
	display:inline-block;
	box-sizing:border-box;
	margin:0;
	padding:0;
	position:relative;
	float:left;
	background: $white;
	font-family: 'Merriweather', serif;
	
	&:first-of-type{
		margin-top:1rem;
	}

	.grid-item{
		display:inline-block;
		position:relative;
		box-sizing:border-box;
		float:left;
		overflow:hidden;
		height: 50vw;
		
		@media screen and (min-width:$tablet){
			height: 20vw;
  		max-height: 15rem;
  	}
		
		&.natural{
			height: auto;
			max-height: none;
			min-height: 0;
			padding:3rem 2rem;
			text-align: center;
			.intro-text {
  			line-height: 1.25rem;
  			font-weight: lighter;
  			font-size: 1rem;
			}
		}
		
		&.black-box{
			background:$black_bg;
			color:$white;
		}
		&.span-1{
			width:50%;
			@media screen and (min-width:$tablet){
				width:20%;
			}
		}
		&.span-2{
			width:100%;
			@media screen and (min-width:$tablet){
				width:40%;
			}
		}
		&.span-3{
			width:100%;
			@media screen and (min-width:$tablet){
				width:60%;
			}
		}
		&.span-4{
			width:100%;
			@media screen and (min-width:$tablet){
				width:80%;
			}
		}
		&.span-5{
			width:100%;
			@media screen and (min-width:$tablet){
				width:100%;
			}
		}
		
		&.desktop{
			display:none;
			@media screen and (min-width:$tablet){
				display:inline-block;
			}
		}
		
		.inner{
			position:absolute;
			top:0;
			left:0;
			right:0;
			bottom:0;
			
			@import "inner-row-content/text_items";
			
			@import "inner-row-content/image_items";
			
		}
	}
	&.double-height{
		.grid-item{	
			min-height:70vh;
			@media screen and (min-height:$low_vertical){
				min-height:40vh;
				@media screen and (min-width:$tablet){
					min-height:40vh;
				}
				@media screen and (min-width:$desktop){
					min-height:48vh;
				}
			}
		}
	}
	
}

