//  Table of Contents:
//
//   1. Global
//   2. Breakpoints
//   3. The Grid
//   4. Base Typography
//   5. Typography Helpers
//   6. Abide
//   7. Accordion
//   8. Accordion Menu
//   9. Badge
//  10. Breadcrumbs
//  11. Button
//  12. Button Group
//  13. Callout
//  14. Close Button
//  15. Drilldown
//  16. Dropdown
//  17. Dropdown Menu
//  18. Flex Video
//  19. Forms
//  20. Label
//  21. Media Object
//  22. Menu
//  23. Off-canvas
//  24. Orbit
//  25. Pagination
//  26. Progress Bar
//  27. Reveal
//  28. Slider
//  29. Switch
//  30. Table
//  31. Tabs
//  32. Thumbnail
//  33. Title Bar
//  34. Tooltip
//  35. Top Bar


// 0. queries
// -------------



// 1. GLOBAL
// -------------

$mirr-font: 'Merriweather', serif;
$font-Times: Times, serif;
$mirr-sans-font: 'Merriweather Sans', sans-serif;
$mirr-font-regular: 'Merriweather', serif;
$mirr-font-light: 'Merriweather' lighter;
$font-lighter: lighter;

$global-black-grey: rgb(25,25,25);

$global-black-grey-opaque:rgba(25,25,25,0.7);
$global-white: #fff;
$global-white-hovered:#CECDCD;

$global-header-footer-black: $global-black-grey;

$global-text-shadow-vector: 1px 1px 1px;
$global-text-shadow-color: rgba(0, 0, 0, 1);
$global-text-shadow: $global-text-shadow-vector $global-text-shadow-color;

$global-text-on-black: white;
$global-button-background-color:#9A7C61;
$global-button-background-color-hover:#725C4A;

//   2. Breakpoints
//   3. The Grid
//   4. Base Typography
// --------------

$font-size-50: 2.7rem;
$font-size-42: 2rem;
$font-size-31: 1.95rem;
$font-size-30: 1.9rem;
$font-size-28: 1.7rem;
$font-size-23: 1.6rem;
$font-size-22: 1.5rem;
$font-size-21: 1.3rem;
$font-size-20: 1.2rem;
$font-size-17: 1.05rem;
$font-size-14: 0.9rem;
$font-size-sm:88%; /*14px*/
$font-size-v-sm:77%; /*12px*/
$font-size-default:100%; /* some px (16?)*/

$leading-48pt: 3rem;
$leading-38pt: 2.4rem;
$leading-37pt: 2.3rem;
$leading-35pt: 2.1rem;
$leading-30pt: 1.9rem;
$leading-29pt: 1.85rem;
$leading-27pt: 1.8rem;
$leading-21pt: 1.6rem;
$leading-19pt: 1.4rem;
$leading-default: 1.2rem;
$leading-lower-default:1rem;

$menu-text-transform:uppercase;
$menu-text-shadow: $global-text-shadow-vector $global-text-shadow-color;

// 4.1. Booking Menu Typography
$booking-menu-text-color: $global-text-on-black;




// --------------

//   5. Typography Helpers
//   6. Abide
//   7. Accordion
//   8. Accordion Menu
//   9. Badge
//  10. Breadcrumbs
//  11. Button
//  12. Button Group
//  13. Callout
//  14. Close Button
//  15. Drilldown
//  16. Dropdown
//  17. Dropdown Menu
//  18. Flex Video
//  19. Forms
.field-validation-error{
    color:red;
    font-size:0.875rem;
}

//  20. Label
//  21. Media Object
//  22. Menu
//  23. Off-canvas
//  24. Orbit
//  25. Pagination
//  26. Progress Bar
//  27. Reveal
//  28. Slider
//  29. Switch
//  30. Table
//  31. Tabs
//  32. Thumbnail
//  33. Title Bar
//  34. Tooltip
//  35. Top Bar



$black_bg: #191919;