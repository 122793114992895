// 0 - Global
.contain {
    max-width: 1200px;
    margin:0 auto;
}
.full-width, .oversizeRow{
    width:100%;
    max-width:100%;
}
.button{
    background-color:$global-button-background-color;
    text-transform:uppercase;
}
.button:hover, .button:focus{
    background-color:$global-button-background-color-hover;
}
.no-left-padding{
    padding-left:0 !important;
}
.no-right-padding{
    padding-right:0 !important;
}
.no-left-margin{
    margin-left:0!important;
}
.no-right-margin{
    margin-right:0!important;
}
.enormous-padding{
    padding-top:1.45rem;
    height:0;
}
.very-large-padding{
    padding-top:4rem;
}
.very-small-padding-bottom{
    padding-bottom:1rem;
}

.content{
    padding:1rem;
}
.align-right{
    text-align:right;
}

.isPhoneLink{
    color:white;
    &:hover{
        color:white;
    }
}


.welcome-message{
	position:fixed;
	top:30%;
	left:50%;
	padding:0 1rem;
	background:#191919;
	color:white;
	z-index:10000;
	width:23.75rem;
	max-width:100%;
	transform:translate(-50%,-30%);
	.content{
		position:absolute;
		text-align: center;
    top: 0;
    left: 0;
    right: 0;
		bottom:0;
		padding:0;
		.inner{
			position:absolute;
			top:20%;
			transform:translate(0,-20%);
			left:0;
			right:0;
			padding:1rem;
			.title{
				font-size:2rem;
				line-height:2em;
				padding-bottom:1rem;
				@media #{$small-only} {
					padding-bottom:0;
					line-height:1.25em;
				}
			}
			.message{
				padding:0 5%;
			}
		}
		.button{
			position:absolute;
			bottom:10%;
			transform:translate(-50%,0);
			min-width:70%;
			left:50%;
		}
		/*Square box this*/
	}
	.close-dialog{
		position:absolute;
		color:white;
		top:1rem;
		right:1rem;
		left:0;
		text-align:right;
		text-transform:uppercase;
		font-size:0.9rem;
	}
		&:before{
			content: "";
			display: block;
			padding-top: 100%;  /* ratio of 1:1*/
		}
}



@media #{$small-only} {
    .enormous-padding{
        display:none;
    }
}