/* At least I kept them all in the same place... */

.custContent-1072 {
    /*Food menu items*/
    ul.customContentPagesMenu {
        li {
            width: 18%;

            &:last-of-type {
                width: 26%;
            }
        }
    }
}

.row::before, .row::after {
    content: none;
}

.row#raisedGallery::before, .row#raisedGallery::after {
    content: ' ';
}

.column.forceMeRight {
    float: right;
}


@media #{$small-only} {
    .custContent-1072 {
    /*so much shame.scss*/
    ul.customContentPagesMenu {
        li {
            &:last-of-type {
                width: 100%;
                }
            }
        }
    }
}


.mini-widget-text-area p{
    padding:1rem;
    color:white;
}