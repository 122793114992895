#headCopy{
    >div{
        height:240px;
        padding:3rem 1rem;
        h2{
            font-size:2rem;
            line-height:2.8rem;
            font-weight:300;
        }
    }
}

.singleRoomRow{
    color:$global-text-on-black;
    background: $global-black-grey;
    margin-bottom:1rem;
    font-size:$font-size-sm;
    width: 100%;
    &:first-of-type{
			margin-top:1rem;
		}
		a{
        color:$global-text-on-black;
        
    }
    .morelink{
        padding:0;
        display:block;
       
    }
    h2{
        font-style:normal;
        line-height:$leading-48pt;
        font-size:$font-size-28;
        margin-bottom: .25rem;
    }
    h4{
        font-style: italic;
        line-height:$leading-29pt;
        font-size:$font-size-21;
        margin-bottom: 1rem;
    }
    h1, h2, h3, h4{
        text-align:left;
        font-weight:lighter;
    }
    .padded{
        padding:1rem;
    }
    .roomContent{
        text-align:left;
        padding: 1rem;
        p{
            font-size:$font-size-default;
            line-height:$leading-19pt;
        }
    }
    .roomsMainContent{
        
    }
    .accordion{
        background:none;
        border:none;
    }
    .roomBookNowButton{
        float:right;
        width: 155px;
        margin:0;
    }
    .subcontent{
        color:$global-text-on-black !important;
    }
    
}
.roomContent{
    h2{
        line-height:$leading-38pt;
        padding:0;
    }
}
.singleRoomRow#headCopy{
    margin-bottom:0;
}

.roomFootContent{
    h4 p, p span{
       font-size:$font-size-21;
       line-height:$leading-29pt;
       font-style:italic;
    }
}

@media #{$small-only} {
    #headCopy{
        >div{
            height: auto;
            padding:3rem 1rem;
            h2{
                font-size:1.5rem;
                line-height:1.8rem;
                font-weight:300;
            }
        }
    }
}