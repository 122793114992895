.review{
    .italic{
        font-style:italic;
        margin:0;
    }
    p{
        font-family:$mirr-font;
        font-weight:$font-lighter;
        font-style:italic;
        font-size:$font-size-22;
        line-height:$leading-35pt;
        
    }
    p.quotemarks{
        font-size:$font-size-50;
        line-height:$leading-38pt;
        font-weight:100;
        font-family:$font-Times;
        margin-bottom:0;
        /*margin-top:35px;*/
        }
    a{
        text-transform:uppercase;
        font-style:none;
        color:black;
    }
    p, a{
        text-align:center;
        display:block;
    }
    #reviewSlideshow{
        height:auto;
    }
    
}


.quotecopy{
    font-size:$font-size-20;
    line-height:$leading-30pt;
}
.quotereadmore{
    font-size:$font-size-sm;
    display:none;
}
.reviewSlidecontainer{
    padding:0 2rem;
}
.reviewSlideshow.slick-slider {
    margin-bottom:0;
    padding:1.6rem 0;
}